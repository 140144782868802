define("api-charter/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "87Qs1KKD",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"min-h-screen flex lg:items-start items-center justify-center bg-gray-50 lg:pt-48 py-12 px-4 sm:px-6 lg:px-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"max-w-md w-full p-12 rounded overflow-hidden shadow-lg\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,0,\"mx-auto\"],[14,\"src\",\"/assets/images/Shift4-Payments-logo-full.png\"],[14,\"alt\",\"Shift4 Payments\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"mt-6\"],[12],[2,\"\\n            \"],[10,\"a\"],[15,6,[31,[[32,1,[\"authUrl\"]]]]],[14,0,\"group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"absolute left-0 inset-y-0 flex items-center pl-3\"],[12],[2,\"\\n                    \"],[10,\"svg\"],[14,0,\"h-5 w-5 group-hover:text-blue-400 transition ease-in-out duration-150\"],[14,\"fill\",\"currentColor\"],[14,\"viewBox\",\"0 0 20 20\"],[12],[2,\"\\n                        \"],[10,\"path\"],[14,\"fill-rule\",\"evenodd\"],[14,\"d\",\"M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z\"],[14,\"clip-rule\",\"evenodd\"],[12],[13],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n                Login with Google\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "api-charter/templates/login.hbs"
    }
  });

  _exports.default = _default;
});