define("api-charter/templates/form-submitted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tecEZHPP",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"Success\"],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"relative my-20 w-full flex items-center justify-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bg-white rounded shadow py-10 px-16 max-w-lg text-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n            \"],[10,\"svg\"],[14,0,\"h-24 w-24 mx-auto stroke-current text-green-500\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"fill\",\"none\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"stroke\",\"currentColor\"],[12],[2,\"\\n                \"],[10,\"path\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"d\",\"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"text-5xl font-medium leading-tight text-gray-900\"],[12],[2,\"\\n                Thank You\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-2xl font-light leading-tight text-gray-900\"],[12],[2,\"Your form has been submitted\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "meta": {
      "moduleName": "api-charter/templates/form-submitted.hbs"
    }
  });

  _exports.default = _default;
});