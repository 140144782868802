define("api-charter/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LH61iNei",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"API Charter\"],[[\"prepend\"],[true]]]],[2,\"\\n\"],[8,\"notification-container\",[],[[\"@position\"],[\"top\"]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-col min-h-screen\"],[12],[2,\"\\n    \"],[8,\"navbar\",[],[[],[]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"main\"],[14,0,\"flex-grow\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "api-charter/templates/application.hbs"
    }
  });

  _exports.default = _default;
});