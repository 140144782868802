define("api-charter/validators/isv-project", ["exports", "ember-changeset-validations/validators", "ember-changeset-conditional-validations/validators/sometimes"], function (_exports, _validators, _sometimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isvCompanyName: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvAddress: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvCity: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvState: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvZip: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvBusinessContactName: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvBusinessContactTitle: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    isvPhone: [(0, _validators.validateFormat)({
      type: 'phone'
    })],
    isvEmail: [(0, _validators.validateFormat)({
      type: 'email'
    })],
    devCompTechnicalContactName: (0, _sometimes.default)([(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })], function (changes, content) {
      return this.get('hasDifferentDevCompany');
    }),
    devCompTechnicalContactTitle: (0, _sometimes.default)([(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })], function (changes, content) {
      return this.get('hasDifferentDevCompany');
    }),
    devCompPhone: (0, _sometimes.default)([(0, _validators.validateFormat)({
      type: 'phone'
    })], function (changes, content) {
      return this.get('hasDifferentDevCompany');
    }),
    devCompEmail: (0, _sometimes.default)([(0, _validators.validateFormat)({
      type: 'email'
    })], function (changes, content) {
      return this.get('hasDifferentDevCompany');
    }),
    interfaceNameVersion: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    projectPurpose: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    boardedClients: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    productTypes: [productTypesValidator()],
    functionalityRequirements: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })],
    submittedBy: [(0, _validators.validatePresence)({
      presence: true,
      message: '{description} is required'
    })]
  };
  _exports.default = _default;

  function productTypesValidator() {
    return function (key, productTypes, oldValue, changes, content) {
      if (productTypes.length > 0) {
        return true;
      }

      return "Product type is required";
    };
  }
});