define("api-charter/components/validation-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @validation.length}}
  <div class="mt-3">
      {{#each @validation as |message|}}
      <p class="text-red-500 text-xs">
          {{message}}
      </p>
      {{/each}}
  </div>
  {{/if}}
  */
  {"id":"k/OGNgP0","block":"{\"symbols\":[\"message\",\"@validation\"],\"statements\":[[6,[37,2],[[32,2,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"mt-3\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"text-red-500 text-xs\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}","meta":{"moduleName":"api-charter/components/validation-message.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});