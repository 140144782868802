define("api-charter/templates/discovery-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FlpB9Jfa",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,0],[\"Discovery Form\"],null]],[2,\"\\n\"],[8,\"discovery-form\",[],[[\"@productTypes\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[\"page-title\"]}",
    "meta": {
      "moduleName": "api-charter/templates/discovery-form.hbs"
    }
  });

  _exports.default = _default;
});