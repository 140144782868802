define("api-charter/services/ajax", ["exports", "ember-ajax/services/ajax", "api-charter/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    namespace: '/api/v1/external',
    host: _environment.default.host,
    session: Ember.inject.service(),
    contentType: 'application/json',
    headers: Ember.computed('session.data', 'session.isAuthenticated', function () {
      return this.get('session.isAuthenticated') ? {
        'auth-token': this.get('session.data.authenticated').token
      } : {};
    }),
    handleResponse: function handleResponse(status) {
      // if we get back a 401, then we should invalidate the session
      if (shouldInvalidateSession(status, this.get('session'))) {
        this.get('session').invalidate();
      }

      return this._super.apply(this, arguments);
    }
  });
  /**
   * Checks if session has to be invalidated.
   *
   * @param {Number} status - status code of response
   * @param {Object} session - session service
   * @returns {Boolean} check result
   */


  _exports.default = _default;

  function shouldInvalidateSession(status, session) {
    return status === 401 && session.get('isAuthenticated');
  }
});