define("api-charter/tailwind.config", [], function () {
  "use strict";

  module.exports = {
    future: {
      removeDeprecatedGapUtilities: true,
      purgeLayersByDefault: true
    },
    purge: [],
    theme: {},
    variants: {},
    plugins: [require("tailwindcss-ember-power-select").plugin()]
  };
});